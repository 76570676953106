import type { OptionModel, PackageModel, SessionLightModel, SessionModel } from '@24hbs/utils';
import { PUBLIC_APP_SHOW_PRICE_WITH_IVA } from '$env/static/public';
import { priceFormatter } from '$lib/utils/formatter';

export const VAT = 22;

const applyVAT = (price: number) => {
  return (price + (price * VAT) / 100);
};

/**
 * Gets the displayed price for a session, package or option
 * 
 * @param type The type of price to display: original or discount?
 * @param courseHasVatFreeSession True if the course that the session belongs to has at least one vat free session
 * @param session The session to get the price from
 * @param packageOrOption The package or option of the session to get the price from
 * 
 * @returns The displayed price
 * 
 */
export function getDisplayedPrice(type: 'discount' | 'original', courseHasVatFreeSession: boolean, session: SessionModel | SessionLightModel, packageOrOption?: PackageModel | OptionModel): string {
  let target: SessionModel | SessionLightModel | PackageModel | OptionModel = session;
  if (packageOrOption) {
    target = packageOrOption;
  }
  const displayedPriceNoVat = type === 'discount' ? target.discountPrice : target.priceNoVat;
  const displayedPriceWithVat = applyVAT(displayedPriceNoVat || 0);
  return session.vatFree === false && courseHasVatFreeSession ? priceFormatter(displayedPriceWithVat) : priceFormatter(displayedPriceNoVat || 0);
}


export function getDisplayedPriceForcedIva(type: 'discount' | 'original', courseHasVatFreeSession: boolean, session: SessionModel | SessionLightModel, packageOrOption?: PackageModel | OptionModel): string {
  let target: SessionModel | SessionLightModel | PackageModel | OptionModel = session;
  if (packageOrOption) {
    target = packageOrOption;
  }
  const displayedPriceNoVat = type === 'discount' ? target.discountPrice : target.priceNoVat;
  const displayedPriceWithVat = applyVAT(displayedPriceNoVat || 0);
  return priceFormatter(displayedPriceWithVat);
}

// check if the product has to show the price with IVA
export function isPriceWithIva(productCode: string) {
  return PUBLIC_APP_SHOW_PRICE_WITH_IVA.split(',').includes(productCode) ? true : false;
}
<script lang="ts">
	import classNames from 'classnames';
	import type {
		CourseLightModel,
		CourseModel,
		SessionLightModel,
		SessionModel
	} from '@24hbs/utils';
	import { CourseType, CourseTypeStore } from '$lib/utils/courses/type-store';
	import {
		getDisplayedPrice,
		getDisplayedPriceForcedIva,
		isPriceWithIva
	} from '$lib/utils/price-utils';

	export let session: SessionModel | SessionLightModel;
	export let disableSoldOut = false;
	export let fullWidth = false;
	export let location: string | null = null;
	export let showLastSeats = false;
	export let showOnSaleDetail: boolean = false;
	export let showPromoDetailLabel: boolean = false;
	export let privatePrice: boolean = false;
	export let isInCard: boolean = false;
	export let isPriceFrom: boolean = false;
	export let course: CourseModel | CourseLightModel | SessionLightModel | SessionModel | null =
		null;
	export let hasVatFree: boolean = false;

	// const cookiesStore = useCookiesStore();
	// const sessionAB = cookiesStore.sessionAB;
	let showPriceWithIva = isPriceWithIva(session?.productCode);
</script>

{#if session && session.priceNoVat !== null && session.priceNoVat > 0 && CourseTypeStore.getCourseTypeByCourseTypeName(session.courseTypeFull) !== CourseType.CorsiLaurea}
	<section class="price-block" class:fullWidth>
		<div class="labels-wrapper">
			{#if !disableSoldOut && session.soldOut}
				<span class="soldout">Sold Out</span>
			{:else if disableSoldOut && session.soldOut && !isInCard}
				<span
					class={classNames('last-seats mx-2', {
						...(session.promoLabelStyle && { [session.promoLabelStyle]: true })
					})}
				>
					Ultimi posti disponibili
				</span>
			{:else if !session.soldOut}
				{#if session.onSale && !isInCard}
					<span
						class={classNames('promo mx-2 md:mb-2', {
							...(session.promoLabelStyle && { [session.promoLabelStyle]: true })
						})}
					>
						{session.onSaleLabel ? session.onSaleLabel : 'Promo'}
					</span>
				{/if}
				{#if showLastSeats && session.lastSeats}
					<span
						class={classNames('last-seats mx-2', {
							...(session.promoLabelStyle && { [session.promoLabelStyle]: true })
						})}
					>
						Ultimi posti disponibili
					</span>
				{:else if showPromoDetailLabel && session.promoDetailLabel}
					<span
						class={classNames('last-seats mx-2', {
							...(session.promoLabelStyle && { [session.promoLabelStyle]: true })
						})}
					>
						{session.promoDetailLabel}
					</span>
				{/if}
			{/if}
		</div>
		<div class="price-group">
			<div class="inline-flex flex-col">
				{#if isPriceFrom}
					<div class="price-from text-xs uppercase h-6">A partire da</div>
				{/if}
				<!-- {#if $sessionAB !== 'B' && session.onSale && isInCard}
          <div
            class={classNames("promo ml-auto", {
              ...(session.promoLabelStyle && { [session.promoLabelStyle]: true }),
            })}
          >
            {session.onSaleLabel ? session.onSaleLabel : "Promo"}
          </div>
        {/if} -->
			</div>
			<span class="inline-flex flex-col">
				<div class="price">
					{#if session.hasBonus && !session.hasDiscount}
						{#if session.vatFree == true || !showPriceWithIva}
							<span class="highlighted-price"
								>{getDisplayedPrice('original', hasVatFree, session)}</span
							>
						{:else}
							<span class="highlighted-price"
								>{getDisplayedPriceForcedIva('original', hasVatFree, session)}</span
							>
						{/if}
					{:else if session.hasDiscount}
						{#if session.vatFree == true || !showPriceWithIva}
							<span class="deleted-price">{getDisplayedPrice('original', hasVatFree, session)}</span
							>
							<span class="highlighted-price"
								>{getDisplayedPrice('discount', hasVatFree, session)}</span
							>
						{:else}
							<span class="deleted-price"
								>{getDisplayedPriceForcedIva('original', hasVatFree, session)}</span
							>
							<span class="highlighted-price"
								>{getDisplayedPriceForcedIva('discount', hasVatFree, session)}</span
							>
						{/if}
					{:else if session.vatFree == true || !showPriceWithIva}
						<span class="highlighted-price"
							>{getDisplayedPrice('original', hasVatFree, session)}</span
						>
					{:else}
						<span class="highlighted-price"
							>{getDisplayedPriceForcedIva('original', hasVatFree, session)}</span
						>
					{/if}
				</div>
				{#if session.vatFree == true}
					<span class="vat-excluded">ESENTE IVA (ART.10)</span>
				{:else if (session.vatFree == false && hasVatFree == true) || showPriceWithIva}
					<span class="vat-excluded">Iva inclusa</span>
				{:else}
					<span class="vat-excluded">Iva esclusa</span>
				{/if}
				{#if privatePrice}
					<div class="private-price">prezzo privati</div>
				{/if}
			</span>
		</div>
	</section>
	{#if showOnSaleDetail && session.onSaleDetail && session.discountPrice > 0}
		{session.onSaleDetail}
	{/if}
{/if}

<style type="text/scss" lang="scss" scoped>
	.price-block {
		@apply flex justify-between;
		@apply ml-auto;
		&.fullWidth {
			@apply flex-wrap justify-start;
		}

		& > span {
			&:first-child {
				@apply ml-0;
			}
			&:last-child {
				@apply mr-0;
			}
		}
	}

	.labels-wrapper {
		@apply flex flex-col justify-between;
	}

	.private-price {
	}

	.promo {
		@apply self-start;
		@apply bg-status-promo py-2 px-3; /* px-6 */
		@apply text-white text-xs font-semibold uppercase tracking-wide;
		@apply rounded;

		&.primi_posti_advance_booking {
			@apply bg-label-primi_posti_advance_booking;
		}
		&.sconto_i {
			@apply bg-label-sconto_i;
		}
		&.super_sconto {
			@apply bg-label-super_sconto;
		}
		&.sconto_ii {
			@apply bg-label-sconto_ii;
		}
		&.nuovo_titolo {
			@apply bg-label-nuovo_titolo text-black;
		}
		&.libero_non_assegnato {
			@apply bg-label-libero_non_assegnato;
		}
	}

	.soldout {
		@apply self-start;
		@apply bg-status-soldout py-2 px-6;
		@apply text-white text-xs font-semibold uppercase tracking-widest;
		@apply rounded;
	}

	.last-seats {
		@apply self-start;
		@apply bg-petrol py-2 px-2;
		@apply sm:max-w-xs md:w-auto;
		@apply text-white text-xs font-semibold uppercase tracking-wide;
		@apply whitespace-nowrap;
		@apply text-center;
		@apply rounded;

		&.primi_posti_advance_booking {
			@apply bg-label-primi_posti_advance_booking;
		}
		&.sconto_i {
			@apply bg-label-sconto_i;
		}
		&.super_sconto {
			@apply bg-label-super_sconto;
		}
		&.sconto_ii {
			@apply bg-label-sconto_ii;
		}
		&.nuovo_titolo {
			@apply bg-label-nuovo_titolo;
		}
		&.libero_non_assegnato {
			@apply bg-label-libero_non_assegnato;
		}
	}

	.promo-detail {
		@apply self-start;
		@apply bg-petrol py-2 px-2;
		@apply sm:max-w-xs md:w-auto;
		@apply text-white text-xs font-semibold uppercase tracking-wide;
		@apply whitespace-nowrap;
		@apply text-center;
		@apply rounded;

		&.primi_posti_advance_booking {
			@apply bg-label-primi_posti_advance_booking;
		}
		&.sconto_i {
			@apply bg-label-sconto_i;
		}
		&.super_sconto {
			@apply bg-label-super_sconto;
		}
		&.sconto_ii {
			@apply bg-label-sconto_ii;
		}
		&.nuovo_titolo {
			@apply bg-label-nuovo_titolo;
		}
		&.libero_non_assegnato {
			@apply bg-label-libero_non_assegnato;
		}
	}

	.price-group {
		@apply text-right;
		@apply ml-auto;

		& .price {
			@apply inline-flex flex-col;
		}
	}

	.deleted-price {
		@apply font-serif text-base md:text-lg leading-none line-through text-gray-900;
	}

	.highlighted-price {
		@apply font-serif text-md md:text-2xl leading-none font-medium;
	}

	.deleted-price + .highlighted-price {
		@apply ml-2;
	}

	.vat-excluded {
		@apply text-black text-xs md:text-sm font-semibold text-opacity-40 uppercase tracking-wide;
	}
</style>
